import type { ComponentType } from "react"
import { BreadcrumbList, ListItem, WithContext } from "schema-dts"

export function withSchema(Component): ComponentType {
    return (props) => {
        const linksArray = extractTextAndHref(props)
        console.log(linksArray)
        // Generate JSON-LD for breadcrumb structured data
        const breadcrumbData: WithContext<BreadcrumbList> = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: linksArray.map((link, i) => {
                return {
                    "@type": "ListItem",
                    "@id": `https://www.krauss-gmbh.com${link.href}`,
                    position: i + 1,
                    name: link.text,
                    item: `https://www.krauss-gmbh.com${link.href}`,
                }
            }),
        }

        return (
            <>
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(breadcrumbData),
                    }}
                ></script>
                <Component {...props} />
            </>
        )
    }
}

function extractTextAndHref(data) {
    const results = []

    // Helper function to search for 'href' in the node recursively
    const findHref = (node) => {
        if (node && typeof node === "object") {
            // Directly check for 'href' in the current object
            if ("href" in node) return node.href

            // Recursively search nested objects
            for (const key in node) {
                const child = node[key]
                if (child && typeof child === "object") {
                    const href = findHref(child)
                    if (href) return href
                }
            }
        }
        return null
    }

    // Function to traverse and extract text and href
    const traverse = (node) => {
        if (!node || typeof node !== "object") return

        // Extract text and find href
        const { text } = node
        const href = text ? findHref(node) : null

        // Store the result if both are present
        if (text && href) {
            results.push({ text, href })
        }

        // Traverse the children only if they are objects or arrays
        for (const key in node) {
            const child = node[key]
            if (child && (typeof child === "object" || Array.isArray(child))) {
                traverse(child)
            }
        }
    }

    // Start traversal from the input data
    traverse(data)

    return results
}
